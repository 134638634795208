@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'NotoSans';
  src: url('../public/assets/fonts/nato-sans/NotoSans-Regular.woff2') format('woff2'),
    url('../public/assets/fonts/nato-sans/NotoSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-Light';
  src: url('../public/assets/fonts/nato-sans/NotoSans-Light.woff2') format('woff2'),
    url('../public/assets/fonts/nato-sans/NotoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: url('../public/assets/fonts/nato-sans/NotoSans-Medium.woff2') format('woff2'),
    url('../public/assets/fonts/nato-sans/NotoSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: url('../public/assets/fonts/nato-sans/NotoSans-SemiBold.woff2') format('woff2'),
    url('../public/assets/fonts/nato-sans/NotoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('../public/assets/fonts/nato-sans/NotoSans-Bold.woff2') format('woff2'),
    url('../public/assets/fonts/nato-sans/NotoSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: NotoSans, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
